import axios from 'axios';
import Vue from 'vue';

function unwrap_genres(film) {
    film['genres_flat'] = []
    film.genres.forEach(genre => film['genres_flat'].push(genre.id))
}

const moduleCatalogue = {
    state: {
        catalogue: [],
    },
    mutations: {
        SET_CATALOGUE(state, catalogue) {
            catalogue.forEach(film => {
                unwrap_genres(film);
            })
            Vue.set(state, 'catalogue', catalogue)
        },
        ADD_FILMS(state, catalogue) {
            catalogue.forEach(film => {
                let index = state.catalogue.findIndex(item => item.id === film.id);
                if (!film['genres_flat']) {
                    unwrap_genres(film)
                }
                if (index < 0) {
                    state.catalogue.push(film)
                }
                else {
                    state.catalogue[index] = film;
                }
            })
        },
    },
    getters: {
        CATALOGUE(state) {
            return state.catalogue;
        },
        SORTED_CATALOGUE(state) {
            return state.catalogue.sort((a, b) => {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1
                }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1
                }
                return 0
            });
        },
    },
    actions: {
        GET_CATALOGUE_API({commit}) {
            axios
                .get('/api/v1/film', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                .then(response => {
                    commit('SET_CATALOGUE', response.data.objects)
                    commit('SET_SERVICE', {'data': {'has_next' : response.data.has_next, 'page' : 1}, 'field': 'catalogue'})
                })
                .catch(error => console.log(error.response))
        },
    }
};

export default moduleCatalogue;