<template>
    <main>
        <div class="row-wrap">
			<!--  если нет анонса - комментируем
            <h1 style="text-align: center"> СКОРО НА ЭКРАНАХ ВАШИХ КИНОТЕАТРОВ</h1>

            <div class="row offset-top-30" >
                <div class="xsmall-12 medium-6 large-2 col event-card" v-for="(film, index) in anonsCatalogue" :key="'film' + index">



                    <router-link :to="'/catalogue/' + film.id +'/' " href="catalog-page.html">
                        <div class="event-img"  >
                            <img :src="film.poster" alt="">
                            <p class="event-txt">
                                {{ film.tagline }}
                            </p>

                        </div>
                        <p class="h4">{{ film.title }}</p>
                        <span class="description" v-for="(genre, genre_index) in film.genres"
                              :key="'genre' + index + genre.id">
                            {{ genre.title }}<template v-if="genre_index !== film.genres.length - 1">, </template>
                        </span>
                    </router-link>

                </div>
            </div>





			<hr style="border: 1px solid #007cbc;">
			<br> <br>

			-->



             <h1 style="text-align: center">КАТАЛОГ ФИЛЬМОВ</h1>

            <div class="flex-center space-between head-sticky">
                <form class="fas fa-search icon-before">
                    <input type="search" v-model="search">
                </form>

                <!-- js-filters - для примера поведения -->
                <div class="text-right">
                    <div class="btns js-filters">
                        <a class="btn small" :class="[{'active' : isVisible(genre.id)}]"
                           v-for="(genre, index) in SORTED_GENRES" @click.prevent="setFilter(genre.id)"
                           :key="'filter-genre-' + index">{{ genre.title }}</a>
                    </div>
                    <p class="description offset-top-10"><a href="#" class="underline"
                                                            @click.prevent="genre_filter = []">Сбросить фильтры</a></p>
                </div>

            </div>

            <!-- events -->
            <div class="row offset-top-30">

                <div class="xsmall-12 medium-6 large-2 col event-card" v-for="(film, index) in filteredCatalogue"
                     :key="'film' + index">
                    <router-link :to="'/catalogue/' + film.id +'/' " href="catalog-page.html">
                        <div class="event-img">
                            <img :src="film.poster" alt="">
                            <p class="event-txt">
                                {{ film.tagline }}
                            </p>

                        </div>
                        <p class="h4">{{ film.title }}</p>
                        <span class="description" v-for="(genre, genre_index) in film.genres"
                              :key="'genre' + index + genre.id">
                            {{ genre.title }}<template v-if="genre_index !== film.genres.length - 1">, </template>
                        </span>
                    </router-link>
                </div>
            </div>

        </div>
        <!-- events end -->
        <router-view></router-view>
    </main>
</template>

<script>
    import router from "@/Routes"
    import axios from "axios"
    import {mapActions} from 'vuex'
    import {mapGetters} from 'vuex'

    export default {
        name: 'Catalogue',
        data() {
            return {
                router: router,
                search: '',
                genre_filter: [],
            }
        },
        created() {
            document.addEventListener('scroll', this.scrollPage)
        },
        destroyed() {
            document.removeEventListener('scroll', this.scrollPage)
        },
        computed: {
            ...mapGetters([
                'CATALOGUE',
                'SORTED_CATALOGUE',
                'SORTED_GENRES',
                'USER',
                'FILM',
            ]),
            filteredCatalogue() {
                if (this.search !== '') {
                    return this.notAnonsCatalogue.filter(film => {
                        return film.title.toLowerCase().includes(this.search.toLowerCase());
                    })
                } else {
                    if (this.genre_filter.length === 0) {
                        return this.notAnonsCatalogue
                    } else {
                        let response = []
                        for (let film of this.notAnonsCatalogue) {
                            // включающий фильтр
                            for (let genre of this.genre_filter) {
                                if (film.genres_flat.includes(genre)) {
                                    response.push(film)
                                    break
                                }
                            }
                            // исключающий фильтр
                            // response.push(film)
                            // for (let genre of this.genre_filter) {
                            //     if (!film.genres_flat.includes(genre)) {
                            //         response.pop()
                            //         break
                            //     }
                            // }
                        }
                        //this.SORTED_CATALOGUE.forEach(film => {
                        //    if (this.genre_filter.filter(x => film.genres_flat.includes(x)).length > 0) response.push(film);
                        //})
                        return response
                    }
                }
            },
            //isAnons() {
            //console.log("Анонс?")
            // console.log(this.film.valueOf())

            // if (typeof this.film === "object" ) return true
            //  else return false

            //},

            anonsCatalogue() {

                return this.SORTED_CATALOGUE.filter(film => {
                    return film.anons === 1;
                })

            },

            notAnonsCatalogue() {

                return this.SORTED_CATALOGUE.filter(film => {
                    return film.anons === 0;
                })

            },
        },
        methods: {
            ...mapActions(
                [
                    'GET_USER_API',
                    'GET_CATALOGUE_API',
                ]
            ),
            scrollPage() {
                let body = document.body,
                    html = document.documentElement;
                let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
                if (Math.abs(html.scrollTop + html.clientHeight - height) < 1 && this.SERVICE.catalogue.has_next) {
                    axios
                        .get('/api/v1/film/page/' + (this.SERVICE.catalogue.page + 1), {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                withCredentials: true
                            }
                        })
                        .then(response => {
                            this.$store.commit('ADD_FILMS', response.data.objects);
                            this.$store.commit('SET_SERVICE', {
                                'data': {
                                    'has_next': response.data.has_next,
                                    'page': this.SERVICE.catalogue.page + 1
                                }, 'field': 'catalogue'
                            });
                        })
                        .catch(error => console.log(error))
                }
            },
            setFilter(id) {
                if (this.genre_filter.indexOf(id) < 0) this.genre_filter.push(id);
                else this.genre_filter.splice(this.genre_filter.indexOf(id), 1);
            },
            isVisible(id) {
                return this.genre_filter.indexOf(id) !== -1;
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
