<template>
    <div class="tabs-content" style="display: block;">
        <div class="xsmall-12 small-9 medium-6 with-icon">

            <a href="#" class="btn fas fa-pen icon" @click.prevent="openForm('cinema', 'kdm_emails')"></a>
<!--            <a href="#" class="btn fas fa-times icon"></a>-->
            <template v-if="edit === false">
                <ul class="clear-list js-info" v-if="USER.cinema.kdm_emails.length > 0">
                    <li v-for="(email, index) in USER.cinema.kdm_emails.split(',')" :key="'kdm' + index">{{ email }}</li>
                </ul>
            </template>
            <form @submit.prevent="submitData($event, '/api/v1/cinema/0')" v-else>
                <label>Введите адреса через запятую</label>
                <etextarea v-model="temp.kdm_emails" name="kdm_emails" :error="errors['kdm_emails']" @clean_error="cleanError"></etextarea>
                <div class="btns offset-top-20">
                    <button type="submit" class="btn colored">Сохранить</button>
                    <button class="link" @click.prevent="closeForm">Отменить изменения</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from 'vuex'
    import etextarea from "@/assets/js/inputs/etextarea"

    export default {
        name: 'KDM',
        components: {
            etextarea,
        },
        data() {
            return {
                router: router,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
        methods: {
            submitData(event, action) {
                let data =  new FormData(event.target);
                axios
                   .patch(action, data, {
                       headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                    .then(response => {
                        this.$store.commit('UPDATE_CINEMA', response.data.object);
                        this.edit = false
                    })
                    .catch(error => console.log(error.response.data))
            },
            openForm: function(field, extra_field) {
                this.edit = true;
                this.temp = {'kdm_emails' : this.USER[field][extra_field]};
            },
        },

    }
</script>

<style>
</style>
