<template>
            <div class="xsmall-12 medium-6 section">
                <span class="fas fa-check-circle large-icon offset-bottom-20"></span>
                <h1>Спасибо за регистрацию!</h1>
                <router-link to="/login" class="btn colored">Перейти к авторизации</router-link>
            </div>
</template>

<script>
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"



    export default {
        name: 'ConfirmationSuccess',
        data() {
            return {
                router: router,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
    }
</script>

<style>
</style>
