<template>
    <div class="tabs-content active">
        <div class="xsmall-12 small-9 medium-6 with-icon">
        <template v-if="edit === false">
            <a class="btn fas fa-pen icon" @click.prevent="openForm('cinema')"></a>
<!--            <a href="#" class="btn fas fa-times icon"></a>-->

            <table class="js-info">
                <tbody><tr v-if="USER.cinema.title">
                    <th>Название кинотеатра</th>
                    <td>{{ USER.cinema.title }}</td>
                </tr>
                <tr>
                    <th>Город</th>
                    <td>{{ USER.cinema.city.title }}</td>
                </tr>
                <tr v-if="USER.cinema.address">
                    <th>Адрес</th>
                    <td>{{ USER.cinema.address }}</td>
                </tr>
                <tr v-if="USER.cinema.phone">
                    <th>Телефон</th>
                    <td>{{ USER.cinema.phone }}</td>
                </tr>
                <tr v-if="USER.cinema.site">
                    <th>Сайт</th>
                    <td>{{ USER.cinema.site}}</td>
                </tr>

                <tr>
                    <th>Email, указанный при регистрации</th>
                    <td>{{ USER.email }}</td>
                </tr>
                <tr v-if="USER.cinema.email">
                    <th>Email кинотеатра</th>
                    <td>{{ USER.cinema.email }}</td>
                </tr>
                </tbody></table>
            <div v-if="!checkTrustedUser" class="message offset-top-20">
                 <p>Уважаемый пользователь {{ USER.cinema.title }}!</p>
                 <p>Для работы с нашим ресурсом Вам необходимо предварительно подписать два документа:</p>
                <p> - <a  target="_blank" href="https://cinemaemotion.pro/static/agreement_pro___.docx">Пользовательское соглашение </a>об использовании ресурса Cinemaemotion.PRO</p>
                <p> - <a  target="_blank" href="Contract_PRO.docx">Сублицензионный договор </a> с компанией АО "Невафильм" </p>
                <p>По поводу подписания документов обращайтесь по адресу emotion@nevafilm.ru</p>
            </div>



        </template>

            <form @submit.prevent="submitData($event, '/api/v1/cinema/0')" v-if="edit">

                <label>Название</label>
                <einput type="text" v-model="temp.title" name="title" :error="errors['title']" @clean_error="cleanError"></einput>
                <!--            <input type="text" autofocus="" name="title" v-model="title">-->
                <p class="description error" v-if="checkTitle">Данное поле является обязательным для заполнения</p>
                <label>Город</label>
                <ewrapper :error="errors['city']">
                    <Dataset @search="getCities" :init_value="temp.city.id" :options="cities" @input="temp.city.id = $event ? $event.id : null; cleanError('city')"
                             label="title" :class="[{'error' : checkCity}]" @clean="city = null"></Dataset>
                </ewrapper>
                <p class="description error" v-if="checkCity">Выберите город из списка</p>
                <input type="hidden" name="city" v-model="temp.city.id">

                <label>Адрес</label>
                <einput type="text" name="address" v-model="temp.address" :error="errors['address']" @clean_error="cleanError"></einput>

                <label>Телефон</label>
                <einput type="text" v-model="temp.phone" name="phone" :error="errors['phone']" @clean_error="cleanError"></einput>

                <label>Сайт</label>
                <einput type="text" v-model="temp.site" name="site" :error="errors['site']" @clean_error="cleanError"></einput>

                <label>Email кинотеатра</label>
                <einput type="email" v-model="temp.email" name="email" :iclass="[{'error' : checkEmail}]" :error="errors['email']" @clean_error="cleanError"></einput>
                <p class="description error" v-if="checkEmail">Введите действительный адрес электронной почты</p>

                <div class="btns offset-top-20">
                    <button type="submit" class="btn colored">Сохранить</button>
                    <button class="link" @click.prevent="closeForm">Отменить изменения</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from 'vuex'
    import Dataset from "@/assets/js/Dataset";
    import einput from "@/assets/js/inputs/einput";
    import ewrapper from "@/assets/js/inputs/ewrapper";

    export default {
        name: 'Info',
        components: {Dataset, einput, ewrapper},
        data() {
            return {
                router: router,
                cities: [],
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
            checkEmail() {
                const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (this.temp.email !== null && this.temp.email !== '') {
                    if (email.test(this.temp.email)) {
                        return false
                    } else return true
                }
                else return false
            },
            checkTitle() {
                if (this.temp.title !== null) return this.temp.title.length < 1;
                else return false
            },
            checkCity() {
                if (this.temp.city !== null) return this.temp.city.length < 1;
                else return  false
            },
            checkTrustedUser() {
                console.log   (this.USER.valueOf())
                return this.USER.cinema.trusted_user===1;
            },
        },
        methods: {
            submitData(event, action) {
                let data =  new FormData(event.target);
                axios
                    .patch(action, data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        this.$store.commit('UPDATE_CINEMA', response.data.object);
                        this.edit = false
                    })
                    .catch(error => this.setError(error.response.data))
            },
            getCities(pattern) {
                this.$set(this, 'filterPattern', pattern)
                if (pattern.length === 3) {
                    axios.get(`/api/v1/city/${pattern}`)
                        .then(response => {
                            this.$set(this, 'cities', response.data)
                        })
                }
            },
            openForm(field, extra_field) {
                this.edit = true;
                if (!extra_field) this.temp = this.deepCopy(this.USER[field])
                else this.temp = this.deepCopy(this.USER[field][extra_field])
                if (this.temp.city) this.cities.push(this.temp.city);
            },
        },
    }
</script>

<style>
</style>
