<template>
    <main>

        <div class="row-wrap center-block">
            <router-view></router-view>
        </div>

    </main>
</template>

<script>
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"



    export default {
        name: 'Confirmation',
        data() {
            return {
                router: router,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
    }
</script>

<style>
</style>
