<!--
Emit list:

    0.1 v-model - записывает в v-model выбранный объект
    0.2 v-model - при вызове clean происходит очистка v-model (записывается undefined)
    1. input - возвращает выбранный объект (число, строку, объект), либо строку, введеную пользователем, если allowInput включен
    2. search - возвращает введенную строку каждый ввод символа. Например, для отправки запроса на сервер за новым набором options, либо редактирования уже известного options
    3. clean - возбуждается, когда нажат крестик на вводе, ничего не возвращает
 -->

<template>
    <div class="datalist" ref="_root_" v-click-outside="blur">
        <div style="position: inherit">
            <button v-if="selected" type="button" title="Clear selection" class="clear" v-on:click="clean">
                <span>×</span>
            </button>
        </div>
        <input type="text" :class="input_class" :required="required" :disabled="disabled" :id="id" :name="name" ref="input"
               :placeholder="placeholder" autocomplete="off"
               v-on:keyup="pressKey"
               v-on:keydown.enter.prevent
               v-on:input="input"
               v-on:focus="focus"
               title="Поддерживаются стрелки на клавиатуре, клавиши Enter и Esc">
        <div style="position: inherit">
            <ul v-if="options && options.length > 0" ref="list">
                <li v-for="(option, index) in filters" :key="index" :class="{'highlight': isSelect(option)}"
                    v-on:click="select($event, option)" v-on:mouseover="mouseOver">
                    <slot name="select-option" :option="option">
                        <template v-if="option[label]">
                            {{ option[label] }}
                        </template>
                        <template v-else>
                            {{ option }}
                        </template>
                    </slot>
                </li>
            </ul>
            <ul v-else>
                <li>{{ startMessage }}</li>
            </ul>
        </div>
    </div>
</template>

<script>

    import ClickOutside from './clickoutside.js'

    export default {
        name: 'dataset',
        directives: {
            ClickOutside: ClickOutside,
        },
        props: {
            // список опций для выбора
            options: {
                type: [Array, Object],
                required: false,
                default: null
            },
            // начальное сообщение (не плейсхолдер)
            startMessage: {
                type: String,
                default: 'Начните вводить...',
            },
            // тег имени для инпута
            name: {
                type: String,
                default: null,
            },
            // тяжело догадаться
            placeholder: {
                type: String,
                default: null,
            },
            // см. плейсхолдер
            id: {
                type: String,
                default: null,
            },
            // см. ид
            required: {
                type: Boolean,
                default: false,
            },
            // Не лейбл для поля, используется для вывода значения в списке, если список состоит из объектов
            label: {
                type: String,
            },
            // Можно ли ввести что-то свое, не выбирая элемент в списке
            allowInput: {
                type: Boolean,
                default: false,
            },
            // инициализирующее значение, сложна логика что пипец, сам еще до конца не придумал, так что оставлю просто тут
            init_value: {},
            value: {
                default: undefined,
            },

            // класс для инпута, чтобы не производить коллизий с классом для внешнего дива
            input_class: {},
            // поле для сравнения в инициализации
            compare_by: {
                type: String,
                default: 'id',
            },
            disabled: {
                type: [String, Boolean],
                default: false,
            }
        },
        data: function() {
            return {
                selected: null,
                current: null,
                inputValue: null,
            }
        },
        mounted: function() {
            this.refreshInitValue()
        },
        watch: {
            init_value: {
                handler: function () {
                    this.refreshInitValue()
                },
                deep: true,
            },
            value: {
                handler: function () {
                    this.refreshInitValue()
                },
                deep: true,
            },
        },
        computed: {
            filters: function() {
                let arr = []
                // вспомнить нах это было нужно
                if (this.inputValue) {
                    for (let i = 0; i < this.options.length; i++) {
                        if (Object.keys(this.options[i]).indexOf(this.label) !== -1) {
                            if ((this.options[i][this.label] + '').toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1) {
                                arr.push(this.options[i])
                            }
                        } else {
                            if ((this.options[i] + '').toLowerCase().indexOf(this.inputValue.toLowerCase()) !== -1) {
                                arr.push(this.options[i])
                            }
                        }
                    }
                } else {
                    arr = this.options
                }
                return arr;
            }
        },
        methods: {
            refreshInitValue: function() {
                let initial = undefined
                if (this.value) {
                    initial = this.value
                } else {
                    initial = this.init_value
                }
                if (initial) {
                    if (Object.keys(this.$refs).length > 0) {
                        let keys = Object.keys(this.options)
                        for (let i = 0; i < keys.length; i++) {
                            if (this.options[keys[i]][this.compare_by] === initial[this.compare_by]) {
                                this.$set(this, 'selected', this.options[keys[i]])
                                if (this.label) {
                                    this.$refs['input'].value = this.selected[this.label]
                                }
                                else {
                                    this.$refs['input'].value = this.selected
                                }
                            } else if (this.options[keys[i]][this.compare_by] === initial) {
                                this.$set(this, 'selected', this.options[keys[i]])
                                this.$refs['input'].value = this.selected[this.label]
                            }
                        }
                    }
                }
            },
            focus: function() {
                this.$refs['_root_'].classList.add('open')
            },
            blur: function() {
                if (this.$refs['_root_'] !== undefined) {
                    this.$refs['_root_'].classList.remove('open')
                }
                if (!this.selected) {
                    if (this.allowInput) {
                        this.$emit('input', this.$refs['input'].value)
                    } else {
                        this.$refs['input'].value = ''
                        this.inputValue = ''
                    }
                }
            },
            pressKey: function(event) {
                event.preventDefault()
                if (event.keyCode === 38 || event.keyCode === 40) {
                    if (Object.keys(this.$refs).indexOf('list') !== -1) {
                        if (!this.current) {
                            this.toggleCurrent(this.$refs['list'].firstChild)
                        } else {
                            if (event.keyCode === 38) {
                                if (this.current.previousElementSibling && 'li' === this.current.previousElementSibling.tagName.toLowerCase()) {
                                    this.toggleCurrent(this.current.previousElementSibling)
                                }
                            } else if (event.keyCode === 40) {
                                if (this.current.nextElementSibling && 'li' === this.current.nextElementSibling.tagName.toLowerCase()) {
                                    this.toggleCurrent(this.current.nextElementSibling)
                                }
                            }
                        }
                    }
                } else if (event.keyCode === 13) {
                    if (this.current) {
                        this.current.click()
                    } else {
                        this.blur()
                    }
                } else if (event.keyCode === 27) {
                    this.blur()
                } else if (event.keyCode === 8) {
                    this.selected = null
                }
            },
            toggleCurrent: function(newElement) {
                if (this.current) {
                    this.current.classList.remove('highlight')
                }
                this.current = newElement
                this.current.classList.add('highlight')

            },
            mouseOver: function(event) {
                if (this.current && event.target !== this.current) {
                    this.current.classList.remove('highlight')
                    this.current = event.target
                    event.target.classList.add('highlight')
                } else if (!this.current) {
                    this.current = event.target
                    event.target.classList.add('highlight')
                }
            },
            select: function(event, entity) {
                this.selected = entity
                this.change()
                this.blur()
            },
            input: function() {
                if (!this.$refs['_root_'].classList.contains('open')) {
                    this.focus()
                }
                this.$emit('search', this.$refs['input'].value, true)
                this.inputValue = this.$refs['input'].value;
            },
            change: function() {
                if (this.selected) {
                    this.$emit('input', this.selected)
                    if (this.selected[this.label] !== undefined) {
                        this.$refs['input'].value = this.selected[this.label]
                    } else {
                        this.$refs['input'].value = this.selected
                    }
                }
            },
            isSelect: function(option) {
                if (this.current && this.current === option) {
                    return true
                }
            },
            clean: function() {
                this.$emit('clean');
                this.$emit('input', undefined)
                this.selected=null;
                this.inputValue = null;
                this.$refs['input'].value = ''
            }
        },
    }
</script>

<style>
    div.datalist {
        box-sizing: border-box;
        position: relative;
        font-family: sans-serif;
        height: 100% !important;
    }

    div.datalist ul {
        display: none
    }

    div.datalist.open ul {
        position: absolute;
        z-index: 9999;
        display: block;
        max-height: 400px;
        top: 100%;
        left: 0;
        min-width: 160px;
        padding: 5px 0;
        margin: 0;
        width: 100%;
        overflow-y: scroll;
        border: 1px solid rgba(0, 0, 0, .26);
        border-radius: 0 0 4px 4px;
        text-align: left;
        list-style: none;
        background: #fff;
        -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.75);
    }

    div.datalist.open ul li {
        margin: 0;
        padding: 5px;
        border-bottom: 1px solid #e6e6e6;
    }

    div.datalist.open ul li:hover {
        cursor: pointer;
    }

    div.datalist.open ul li.highlight {
        background: #3076dc;
        color: #fff;
    }

    div.datalist input {
        max-width: 100%;
        width: 100%;
        padding-right: 20px;
    }

    div.datalist.open input {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    div.datalist .clear {
        position: absolute;
        top: 1px;
        right: 5px;
        font-size: 23px;
        font-weight: 700;
        line-height: 1;
        color: rgba(60,60,60,.5);
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }
</style>
