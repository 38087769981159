<template>
  <div id="app">
        <Navigation @logout="logout_modal = true"/>
        <router-view @logout="logout_modal = true" @contact="contact_modal = true"></router-view>
        <template v-if="logout_modal">
          <div class="modal-bg" style="display: block;"></div>
          <div id="modal-logoff" class="modal small open">

              <div class="modal-header">
                  <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="logout_modal = false"></button>
                  <p class="h3">Вы точно хотите выйти?</p>
              </div>


              <div class="btns">
                  <a href="/api/v1/logout" class="btn">Да</a>
                  <button class="btn colored" @click.prevent="logout_modal = false">Нет</button>
              </div>

          </div>
        </template>
        <Footer></Footer>
  </div>
</template>

<script>
  import 'es6-promise/auto';
  import router from "@/Routes";
  import Navigation from "@/components/Navigation.vue"
  import Footer from "@/components/Footer.vue"
  import {mapActions} from 'vuex'


export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      router,
        logout_modal: false,
    }
  },
    mounted() {
        this.GET_USER_API();
        this.GET_CATALOGUE_API();
        this.GET_GENRES_API();
    },
    methods: {
        ...mapActions(
            [
                'GET_USER_API',
                'GET_CATALOGUE_API',
                'GET_GENRES_API',
            ]
        ),
        logout() {

        },
    },
}
</script>

<style lang="scss">
#app {
 /*margin: 0 auto;*/
 /*width: 100%;*/

}
</style>
