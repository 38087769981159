<template>
    <footer class="row-wrap flex-center space-between description">
        <span>© 2009—{{ currentYear }} <a href="http://nevafilm.ru/" class="underline">Компания «Невафильм»</a></span>
        <a href="/static/privacy_pro.pdf" target="_blank" class="underline">Политика конфиденциальности</a>
        <a href="/static/agreement_pro.pdf" target="_blank" class="underline">Согласие на обработку персональных данных</a>
    </footer>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    // import axios from "axios";
    import 'es6-promise/auto';


    export default {
        name: 'Footer',

        data() {
            return {
            }
        },
        computed: {
            currentYear() {
                return new Date().getFullYear()
            }
        },
    }
</script>

<style>
</style>
