import Vue from 'vue'
import App from './App.vue'
import router from "@/Routes";
import store from "@/Store";
import axios from "axios";

Vue.config.productionTip = false

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

Vue.mixin({
  data: function () {
    return {
      'errors': {},
      'temp' : {},
      'edit' : false,
      'cities' : [],
    }
  },
  methods: {
    cleanError: function() {
      let breadcrumbs = Object.values(arguments)
      let body = this.errors
      let tail = undefined
      for (let i = 0; i < breadcrumbs.length; i++) {
        if (breadcrumbs.length - 1 == i) {
          tail = breadcrumbs[i]
        } else {
          body = body[breadcrumbs[i]]
        }
      }
      if (typeof tail !== "string") {
        tail = tail[0]
      }
      this.$delete(body, tail)
      // this.$delete(this.errors, target)
    },
    setError: function(errors) {
      this.$set(this, 'errors', errors)
    },
    deepCopy: function (obj1, empty_obj = false) {
      var self = this
      var obj = Object.keys(obj1).reduce(function (previous, current) {
        if (!current.match('^[_$]')) {
          if (obj1[current] !== null && obj1[current].constructor.name === "Object") {
            previous[current] = self.deepCopy(obj1[current], empty_obj)
          } else if (obj1[current] === null) {
            previous[current] = null
          } else {
            if (empty_obj === true) {
              previous[current] = ''
            } else {
              previous[current] = obj1[current]
            }
          }
        }
        return previous;
      }, {});
      return obj
    },
    closeForm: function() {
      this.edit = false;
      this.temp = {};
      this.cities = [];
      if (Object.keys(this.errors).length > 0) this.cleanError();
    },
    stringToDateFull: function(string) {
      let date = string.split('-');
      return date[2] + '.' + date[1] + '.' + date[0];
    },
  },
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
