<template>
    <div>
        <template v-if="comp_error">
            <template v-for="(err, index) in error">
                <span class="error" :key="index" v-html="err"></span>
            </template>
        </template>
        <input :type="type" :class="iclass" :min="min" :max="max" :required="required" :disabled="disabled" :value="value" :id="id" v-on:input="input" v-on:change="change" :name="name">
    </div>
</template>

<script>
    export default {
        name: "einput",
        props: {
            error: {
                type: [Object, Array],
                required: false,
            },
            id: {
                type: String,
                required: false,
            },
            accept: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true,
            },
            value: {
                required: false,
            },
            min: {
                required: false,
            },
            max: {
                required: false,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            iclass: {
                type: [String, Array, Object],
                required: false,
            },
            required: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            comp_error: function() {
                return this.error ? this.error : undefined
            },
        },
        methods: {
            input: function($event) {
                this.$emit('input', $event.target.value)
                if (this.comp_error) {
                    this.$emit('clean_error', this.name)
                }
            },
            change: function() {
                if (this.type === 'file') this.$emit('change', document.getElementById(this.id).files[0]);
                if (this.comp_error) {
                    this.$emit('clean_error', this.name)
                }
            }
        }
    }
</script>

<style scoped>
    input {
        width: 100%;
    }
</style>