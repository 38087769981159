<template>
  <header class="row-wrap flex-center space-between">

    <!-- logo -->
    <router-link to="/" class="logo">
      <img src="/static/img/logo.png" alt="">
      <span class="pro">pro</span>

    </router-link>


    <!--<span><b><a href="https://cinemaemotion.pro/static/anons/anons.html">СКОРО НА ЭКРАНАХ ВАШИХ КИНОТЕАТРОВ</a></b></span> -->


    <!-- с классом active - вход выполнен, кнопки скрыты и показана инфа по профилю -->
    <!-- без класса active - вход не выполнен, кнопки видны и скрыта инфа по профилю -->
    <div class="profile" :class="[{'active' : checkUser}]">

      <!-- вход не выполнен -->
      <div class="btns flex-center" v-if="checkUser === false">
        <template v-if="$route.fullPath.includes('reset') === false">
          <router-link :to="$route.fullPath + 'login'" class="btn colored js-modal" @click.prevent="$emit('login')">Вход</router-link>
          <router-link :to="$route.fullPath + 'registration'" @click.prevent="$emit('registration')" class="btn js-modal">Регистрация</router-link>
        </template>
      </div>

      <!-- вход выполнен -->
      <div class="profile-info" v-else>
        <a href="#" class="fas fa-user icon-before">{{ getName }}</a>
        <ul class="profile-nav">
          <li><router-link to="/account/profile/info">Профиль</router-link></li>
          <li><router-link to="/account/requests" href="#">Заявки</router-link></li>
<!--          <li><router-link to="/account/contracts">Договоры</router-link></li>-->
          <li><a @click.prevent="$emit('logout')" style="cursor: pointer">Выйти</a></li>
        </ul>
      </div>

    </div>
  </header>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
import router from "@/Routes"
import { mapGetters } from "vuex";
export default {
  name: 'Navigation',
  mixins: [smoothReflow],
  data() {
    return {
      router: router,
      clientWidth: 0,
    }
  },
  computed: {
    getClientWidth() {
        return this.clientWidth
    },
    ...mapGetters([
      'USER',
    ]),
    checkUser() {
      return Object.keys(this.USER).length > 0 && typeof(this.USER) === 'object';
    },
    getName() {
      if (this.checkUser) {
        if(this.USER.cinema.title) {
          return this.USER.cinema.title;
        }
        else return 'Добро пожаловать'
      }
      else return 'Добро пожаловать'
    },
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.clientWidth = document.documentElement.clientWidth
    },
    openMenu() {
      let burger = document.getElementById('burger');
      if (burger.style.display) {
        burger.style.display = null
      }
      else {
        burger.style.display = 'block';
      }
      this.toggleButton();
    },
    showChildren(link, index) {
      let ul = document.getElementById('children' + index);
      if (ul.style.display) {
        ul.style.display = null
      }
      else {
        ul.style.display = 'block';
      }
    },
    toggleButton() {
      let button = document.getElementById('menu-button');
      button.classList.toggle('active');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .wrapper {
    width: 100%;
  }
  .menu-enter-active {
      max-height: 0;
      transition: max-height 1s ease-out;
      overflow: hidden;
  }
  .menu-leave-active {
    max-height: 0;
    transition: max-height 1s ease-in;
    overflow: hidden;
  }
  .menu-enter, .menu-leave-to {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
    overflow: hidden;
  }
</style>
