<template>
        <div class="row-wrap">
            <h1>Профиль</h1>
            <div class="tabs-wrapper">

                <ul class="tabs btns sticky">
                    <li class="btn" :class="[{'active' : $route.fullPath === '/account/profile/info'}]"><router-link to="/account/profile/info">Основные данные</router-link></li>
<!--                    <li class="btn" :class="[{'active' : $route.fullPath === '/account/profile/contacts'}]"><router-link to="/account/profile/contacts">Контакты</router-link></li>-->
                    <li class="btn" :class="[{'active' : $route.fullPath === '/account/profile/requisites'}]"><router-link to="/account/profile/requisites">Реквизиты</router-link></li>
                    <li class="btn" :class="[{'active' : $route.fullPath === '/account/profile/KDM'}]"><router-link to="/account/profile/KDM">Адреса для рассылки ключей KDM</router-link></li>
                </ul>
                <router-view @contact="$emit('contact')"></router-view>
                
            </div>
        </div>
</template>

<script>
    import router from "@/Routes"
    import 'es6-promise/auto';


    export default {
        name: 'Profile',

        data() {
            return {
                router: router,
            }
        },
        mounted() {

        },
    }
</script>

<style>
</style>
