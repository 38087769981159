<template>
    <main v-if="checkUser">
        <nav class="row-wrap">
            <router-link to="/account/profile/info" :class="[{'active' : $route.fullPath.includes('profile')}]">Профиль</router-link>
            <router-link to="/account/requests" :class="[{'active' : $route.fullPath.includes('/account/requests')}]">Заявки</router-link>
<!--            <router-link to="/account/contracts" :class="[{'active' : $route.fullPath.includes('/account/contracts')}]">Договоры</router-link>-->
            <a @click.prevent="$emit('logout')" style="cursor: pointer">Выйти</a>
        </nav>
        <router-view @contact="$emit('contact')"></router-view>
    </main>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    // import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import { mapGetters } from "vuex";


    export default {
        name: 'Account',
        mounted() {
            setTimeout(() => {if (this.checkUser === false) router.push('/')}, 500)
        },
        data() {
            return {
                router: router,
            }
        },
        watch: {
            USER() {
                if (this.checkUser === false) router.push('/')
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
            checkUser() {
                return Object.keys(this.USER).length > 0 && typeof(this.USER) === 'object';
            },
        },
    }
</script>

<style>
</style>
