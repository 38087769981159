<template>
    <div class="row-wrap">
        <template v-if="checkRoute">
        <h1>Заявки</h1>

        <div class="flex-center head-sticky">
            <form class="fas fa-search icon-before">
                <input type="search" v-model="search">
            </form>
            <router-link to="/" class="btn colored">В каталог фильмов</router-link>
        </div>

        <ul class="links-list complex">
            <li v-for="(request, index) in filteredRequests" :key="'request-' + index">
                <router-link :to="$route.fullPath + '/' + request.id">
                    <div>
                        <span class="title">
                            {{ request.film.title }}
                        </span>
                        <time class="description">{{ stringToDateFull(request.date_added.slice(0, 10))}}</time>
                    </div>
                    <span :class="['tag fas', { 'fa-spinner' : request.status === 'Отправлена', 'fa-times': request.status === 'Отменена', 'fa-check' : request.status === 'Сохранена'}, 'icon-before']">{{ request.status }}</span>
                </router-link>
            </li>
        </ul>


<!--        <div class="flex-center">-->
<!--            &lt;!&ndash;pagination &ndash;&gt;-->
<!--            <ul class="pagination">-->
<!--                <li class="prev"><a href="#"></a></li>-->
<!--                <li><a href="#">1</a></li>-->
<!--                <li>...</li>-->
<!--                <li><a href="#">3</a></li>-->
<!--                <li><a href="#">4</a></li>-->
<!--                <li class="active"><span>5</span></li>-->
<!--                <li><a href="#">6</a></li>-->
<!--                <li><a href="#">7</a></li>-->
<!--                <li>...</li>-->
<!--                <li><a href="#">11</a></li>-->
<!--                <li class="next"><a href="#"></a></li>-->
<!--            </ul>-->
<!--            <a href="#" class="underline description offset-top-20">Показать все</a>-->
<!--        </div>-->
        </template>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from 'vuex'

    export default {
        name: 'Requests',

        data() {
            return {
                router: router,
                search: '',
            }
        },
        computed: {
            checkRoute(){
                return this.$route.fullPath === '/account/requests'
            },
            ...mapGetters([
                'USER',
                'SORTED_REQUESTS',
            ]),
            filteredRequests() {
                if (this.search === '') return this.SORTED_REQUESTS
                else return this.SORTED_REQUESTS.filter(request => {
                    return request.film.title.toLowerCase().includes(this.search.toLowerCase())
                })
            },
        },
        mounted() {
            document.title = 'CinemaEmotion — список заявок';
            axios
                .get('/api/v1/application',{
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                .then(response => this.$store.commit('SET_REQUESTS', response.data.objects))
                .catch(error => console.log(error));
        },
    }
</script>

<style>
</style>
