<template>
    <main>
        <div class="row-wrap">

            <router-link to="/" class="fas fa-caret-left icon-before offset-bottom-20">Вернуться к каталогу</router-link>

            <h1>{{ film.title }}</h1>
            <p>{{ film.year }},
                <template class="description" v-for="country in film.countries">
                    {{ country.title }},
                </template>
                {{ film.age }}+</p>


            <div class="row">


                <div class="xsmall-9 medium-3 col">
                    <img :src="film.poster" alt="">
                    <div class="offset-top-20 text-center">
                      <!--  <a  class="btn colored" @click.prevent="show.info = !show.info">Подать заявку</a> -->
                        <div v-if="checkUser && (film.anons===0)">
                            <div v-if="checkTrustedUser">
                            <button type="submit" :class="['btn colored offset-top-20', {'disabled' : !checkUser}]" :disabled="!checkUser" @click.prevent="submitData">Подать заявку</button>
                            </div>
                        </div>
                        <div class="xsmall-9 medium-3 col" v-if="checkUser && (film.anons===1)">

                                <div class="offset-top-20 text-center"  v-if="checkTrustedUser">
                                <button type="submit" :class="['btn colored offset-top-20']" @click.prevent="anonsSubmitData">Предзаказ</button>
                                </div>
                        </div>

                        <div v-if="!checkUser || !checkTrustedUser">
                         <!--    <a  class="btn fas fa-info icon" @click.prevent="show.info = !show.info"></a>
                            <a href="#" class="fas fa-user icon-before">{{ getName }}</a>

                            <transition appear name="create" v-if="show.info">  -->
                            <div  class="message offset-top-20">
                                <p>Для работы с нашим ресурсом Вам необходимо предварительно подписать два документа:</p>
                                <p> - <a  target="_blank" href="https://cinemaemotion.pro/static/agreement_pro___.docx">Пользовательское соглашение </a>об использовании ресурса Cinemaemotion.PRO</p>
                                <p> - <a  target="_blank" href="https://cinemaemotion.pro/static/Contract_PRO.docx">Сублицензионный договор </a> с компанией АО "Невафильм" </p>
                                <p>По поводу подписания документов обращайтесь по адресу emotion@nevafilm.ru</p>
                            </div>
                            <!-- </transition> -->
                        </div>
                    </div>

        <!-- <div class="btns flex-center" v-if="checkUser === false">
            <template v-if="$route.fullPath.includes('reset') === false">
                <router-link :to="$route.fullPath + 'login'" class="btn colored js-modal" @click.prevent="$emit('login')">Вход</router-link>
                <router-link :to="$route.fullPath + 'registration'" @click.prevent="$emit('registration')" class="btn js-modal">Регистрация</router-link>
            </template>
        </div> -->


                </div>

                <div class="xsmall-12 medium-9 col">
                    <div v-html="film.description"></div>
                    <table class="offset-top-40">
                        <tbody>
                        <tr v-if="film.studio">
                            <th>Студия</th>
                            <td>{{ film.studio }}</td>
                        </tr>
                        <tr v-if="film.directors && film.directors.length > 0">
                            <th>Режиссер</th>
                            <td>
                                <template class="description" v-for="(director, index) in film.directors">
                                    {{ director.name }} {{ director.surname }}<template v-if="index !== film.directors.length - 1">, </template>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="film.actors && film.actors.length > 0">
                            <th>Актеры</th>
                            <td>
                                <template class="description" v-for="(actor, index) in film.actors">
                                    {{ actor.name }} {{ actor.surname}}<template v-if="index !== film.actors.length - 1">, </template>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="film.voices && film.voices.length > 0">
                            <th>Озвучивает</th>
                            <td>
                                <template class="description" v-for="(voice, index) in film.voices">
                                    {{ voice.name }} {{ voice.surname}}<template v-if="index !== film.voices.length - 1">, </template>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="film.duration">
                            <th>Длительность</th>
                            <td>{{ film.duration }} мин.</td>
                        </tr>
                        <tr v-if="film.genres && film.genres.length > 0">
                            <th>Жанр</th>
                            <td>
                                <template class="description" v-for="(genre, index) in film.genres">
                                    {{ genre.title }}<template v-if="index !== film.genres.length - 1">, </template>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="checkUser">
                            <th>Прокатная плата</th>
                            <td v-if="checkUser">{{summ}} % от валового сбора, в т.ч. НДС по ставке 20%</td>
                            <td v-if="!checkUser">{{film.rental_fee_digit}} % от валового сбора, в т.ч. НДС по ставке 20%</td>
                        </tr>
                        <tr v-if="film.rental_license && film.rental_license_from">
                            <th>Прокатное удостоверение</th>
                            <td>№ {{ film.rental_license }} от {{ stringToDateFull(film.rental_license_from) }}</td>
                        </tr>
                        <tr v-if="film.national_film_certificate && film.national_film_certificate_from">
                            <th>Удостоверение национального фильма</th>
                            <td>№ {{ film.national_film_certificate }} от {{ stringToDateFull(film.national_film_certificate_from)}}</td>
                        </tr>
                        <tr v-if="film.dcp">
                            <th>Паспорт цифровой кинокопии(DCP)</th>
                            <td><a :href="film.dcp" target="_blank">скачать</a></td>
                        </tr>
                        <tr>
                            <th>Изображение</th>
                            <td>{{ film.image_resolution }}</td>
                        </tr>
                        <tr>
                            <th>Звук</th>
                            <td>{{ film.sound_mode }}</td>
                        </tr>
                        <tr v-if="film.sound_language && film.sound_language.length > 0">
                            <th>Язык<span v-if="film.sound_language.length > 1">и</span></th>
                            <td>
                                <template class="description" v-for="(language, index) in film.sound_language">
                                    {{ language.title }}<template v-if="index !== film.sound_language.length - 1">, </template>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <th>Русские субтитры</th>
                            <td>
                                <span v-if="film.russian_subtitle">Да</span>
                                <span v-else>Нет</span>
                            </td>
                        </tr>
                        <!--                    <tr v-if="film.subtitles_language && film.subtitles_language.length > 0">-->
                        <!--                        <th>Субтитры</th>-->
                        <!--                        <td>-->
                        <!--                            <template class="description" v-for="(language, index) in film.subtitles_language">-->
                        <!--                                {{ language.title }}<template v-if="index !== film.subtitles_language.length - 1">, </template>-->
                        <!--                            </template>-->
                        <!--                        </td>-->
                        <!--                    </tr>-->
                        <tr v-if="film.ftp_login">
                            <th>Логин</th>
                            <td>{{ film.ftp_login }}</td>
                        </tr>
                        <tr v-if="film.ftp_password">
                            <th>Пароль</th>
                            <td>{{ film.ftp_password }}</td>
                        </tr>
                        <tr v-for="(additional, index) in film.additions" :key="'additional-' + index">
                            <th>{{ additional.title }}</th>
                            <td>
                                <a :href="additional.file ? additional.file : additional.url" download="">
                                    <span v-if="additional.file">скачать</span>
                                    <span v-if="additional.url">{{ additional.url }}</span>
                                </a>
                                <span v-if="additional.size">({{ additional.size }} Гб)</span>
                                <p class="description no-margin" v-if="additional.login">Логин: {{additional.login }}</p>
                                <p class="description no-margin" v-if="additional.password">Пароль: {{additional.password }}</p>
                            </td>
                        </tr>
                        </tbody></table>

                    <a href="#" class="btn colored offset-top-20 small-down-show">Подать заявку</a>

                </div>


            </div>

        </div>
        <template v-if="show_contract_error">
            <div class="modal-bg" style="display: block;"></div>
            <div class="modal small open" style="top: 25%">
                <div class="modal-header">
                    <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="show.contract_error = false"></button>
                    <p class="h3">Не хватает данных</p>
                    <ul>
                        <li v-if="checkRequisite === false" class="pointer"><a @click.prevent="$set(temp, 'create_requisite', true)">Реквизиты кинотеатра</a></li>
                        <li v-if="checkRequestDates === false">Срок показа</li>
                    </ul>
                </div>
            </div>
        </template>
        <router-view></router-view>
    </main>
</template>

<script>

    import router from "@/Routes";
    import axios from "axios";
    import {mapGetters} from 'vuex';
    export default {
        name: 'Film',
        data() {

            return {
                counter:7,
                router: router,
                film: {},
                show: {
                    description: false,
                    info: true,
                    tech: false,
                    requisite: false,
                    contact: false,
                    request: false,
                    contract: false,
                },
            }
        },
        created(){
            document.addEventListener('scroll', this.scrollPage)

        },
        mounted() {
            axios
                .get('/api/v1/film/' + this.$route.params.id, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                .then(response => {
                    this.$set(this, 'film', response.data)
                    document.title = `CinemaEmotion — Информация о фильме "${response.data.title}"`
                })
                .catch(error => console.log(error));
        },
        destroyed() {
            document.removeEventListener('scroll', this.scrollPage)
        },
        computed: {
            ...mapGetters([
                'USER',
                'REQUESTS_BY_ID',

            ]),
            checkCinema() {
                console.log ("------- checkCinema")
                if (this.film.id !== null ) {
                    console.log  (this.film.id)
                    console.log  ("------- true")

                    return false  }

                else {
                    console.log  ("------- false")
                       console.log (this.film.id)

                    return true  }
            },
            summ() {
             return this.film.rental_fee_digit+ this.USER.cinema.bonus
            },
            checkUser() {
                return Object.keys(this.USER).length > 0 && typeof(this.USER) === 'object';
            },
            checkTrustedUser() {

                return this.USER.cinema.trusted_user===1;
            },
            getName() {
                if (this.checkUser) {
                    console.log  ("------- true")
                    console.log  ( (this.USER.valueOf()))
                    console.log  ("------- true")
                    console.log  ( (this.USER.cinema.bonus))
                    console.log  ("------- true")
                    if(this.USER.permission) {
                        return this.USER.permission;
                    }
                    else return 'Добро пожаловать2'
                }
                else return 'Добро пожаловать1'
            },



        },
        watch: {
            USER(newValue) {
                if (newValue) {
                    axios
                        .get('/api/v1/film/' + this.$route.params.id, {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                withCredentials: true
                            }
                        })
                        .then(response => this.$set(this, 'film', response.data))
                        .catch(error => console.log(error));
                }
            },
        },
        methods: {
            submitData() {
                console.log  ("------- submit")
                let data =  new FormData;

                data.append('film', this.film.id);
                axios
                    .post('/api/v1/application', data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        this.$store.commit('SET_REQUEST', response.data.object);
                        this.router.push('/account/requests/' + response.data.object.id)

                    })
                    .catch(error => console.log(error));
            },
            anonsSubmitData() {
                console.log  ("------- Anons submit")
                let data =  new FormData;

                data.append('film', this.film.id);
                axios
                    .post('/api/v1/application', data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        this.$store.commit('SET_REQUEST', response.data.object);
                        this.router.push('/account/requests/' + response.data.object.id)

                    })
                    .catch(error => console.log(error));
            },


        },
    }
</script>

<style scoped>
    .no-margin {
        margin: 0;
    }
    .pointer {
        cursor: pointer;
    }
    .fs-inherit {
        font-size: inherit!important;
    }
    .fade-enter-active, .fade-leave-active {
         transition: opacity .5s;
     }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }
        .create-enter-active {
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .create-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .create-enter-to, .create-leave {
        max-height: 400px;
        overflow: hidden;
    }

    .create-enter, .create-leave-to {
        overflow: hidden;
        max-height: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .error-enter-active {
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .error-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .error-enter-to, .error-leave {
        max-height: 400px;
        overflow: hidden;
    }

    .error-enter, .error-leave-to {
        overflow: hidden;
        max-height: 0;
        /*padding-top: 5px;*/
        /*padding-bottom: 5px;*/
    }
</style>
