<template>
    <div class="row-wrap" v-if="Object.keys(this.request).length > 1 && typeof(this.request) === 'object'">

        <router-link to="/account/requests" href="requests.html" class="fas fa-caret-left icon-before offset-bottom-20">Вернуться к списку заявок</router-link>

        <h1 v-if="!checkAnons">Заявка на фильм {{ request.film.title }}</h1>
        <h1 v-if="checkAnons">Предзаказ на фильм {{ request.film.title }}</h1>
        <div class="row">

            <div class="xsmall-9 medium-3 col">
                <img :src="request.film.poster" alt="">
            </div>
            <div class="xsmall-12 medium-9 col" v-if ="!checkAnons">
                <div class="accordion">
                    <div :class="['accordion-item active', {'active' : show.contract}]">
                        <p class="accordion-title">Договор</p>
                            <div class="accordion-content" style="display: block" @click.stop="">
                                <div class="auto">
                                    <label>Сроки показа</label>
                                    <div class="interval offset-bottom-15" style="margin-top: 0px;">
                                        <transition appear name="error" v-if="checkStatus === false && temp.date_start && temp.date_end && new Date(temp.date_start) > new Date(temp.date_end)">
                                            <p class="description error">Дата начала срока показа не может быть позже даты конца срока показа</p>
                                        </transition>
                                        <transition appear name="error" v-if="checkStatus === false && new Date(temp.date_start) < new Date(getMinDate)">
                                            <p class="description error">Дата начала срока показа не может быть раньше {{ getMinDate.split('-').reverse().join('.') }}</p>
                                        </transition>
                                        <transition appear name="error" v-if="checkStatus === false && new Date(temp.date_end) > new Date(getMaxDate)">
                                            <p class="description error">Дата окончания срока показа не может быть позже {{ getMaxDate.split('-').reverse().join('.') }}</p>
                                        </transition>
                                        <einput type="date" style="display: inline" :iclass="['medium fs-inherit', {'error' : checkStatus === false && new Date(temp.date_start) < new Date(getMinDate)}]" name="date_start" :min="getMinDate" :class="[{'disabled' : checkStatus}]" :disabled="checkStatus" v-model="temp.date_start" :error="errors['date_start']" ></einput>
                                        -
                                        <einput type="date" style="display: inline" :iclass="['medium fs-inherit', {'error' : checkStatus === false && new Date(temp.date_end) > new Date(getMaxDate)}]" name="date_end" :min="getMinDate" :max="getMaxDate" :class="[{'disabled' : checkStatus}]" :disabled="checkStatus" v-model="temp.date_end" :error="errors['date_end']"></einput>
                                        <form method="post" action="/create/contract" target="_blank" style="display: inline" ref="contract">
                                            <button type="button" @click.prevent="createContract"
                                               :class="['btn',
                                               {'disabled' : (
                                                   new Date(temp.date_start) > new Date(temp.date_end) ||
                                                   new Date(temp.date_start) < new Date(getMinDate) ||
                                                   new Date(temp.date_end) > new Date(getMaxDate))||
                                                    checkStatus}]"
                                               :disabled="new Date(temp.date_start) > new Date(temp.date_end) ||
                                                          new Date(temp.date_start) < new Date(getMinDate) ||
                                                          new Date(temp.date_end) > new Date(getMaxDate)||
                                                          checkStatus">
                                                Сформировать заявку</button>
                                            <input type="hidden" name="csrfmiddlewaretoken" v-model="temp.token">
                                            <input type="hidden" name="contact" v-model="temp.contact_id">
                                            <input type="hidden" name="date_start" v-model="temp.date_start">
                                            <input type="hidden" name="date_end" v-model="temp.date_end">
                                            <input type="hidden" name="showtime" v-model="temp.showtime">
                                            <input type="hidden" name="application" :value="$route.params.id">
                                        </form>
                                        <a class="btn fas fa-info icon" @click.prevent="show.info = !show.info"></a>

                                    </div>
                                    <transition appear name="create" v-if="show.info">
                                        <div class="message offset-top-20">
                                            <p>Для подачи заявки ее необходимо сформировать, нажав кнопку "Сформировать заявку". </p>
                                            <p>Копия заявки автоматически загрузится к Вам</p>
                                            <p>Для корректного формирования заявки необходимо, чтобы в профиле были заполнены
                                                <a @click.prevent="$set(temp, 'create_requisite', true)">все реквизиты</a>
                                                (включая контактное лицо), а также указаны сроки показа. Период показа не может превышать более 30 дней. Дата начала показа не ранее следующего дня с даты оформления заявки.
                                            </p>
                                            <p>После успешной генерации заявки нажмите кнопку "Отправить заявку"</p>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                    </div>

                    <button type="submit" :class="['btn colored offset-top-20', {'disabled' : !this.ContractIs}]" :disabled="!this.ContractIs" @click.prevent="sendRequest" >Отправить заявку</button>

                </div>
            </div>
            <div class="xsmall-12 medium-9 col" v-if ="checkAnons">
                <div class="accordion">
                <form action="https://postmail.invotes.com/send"  method="post" id="email_form">
                     <label>Тема</label>
                     <input type="text" name="subject"  :value= "ThemeAnons" />
                    <label>Название кинотеатра:</label>
                    <input type="text" name="text" :value="this.USER.cinema.title " />
                     <label>e-mail, на который мы пришлем Вам уведомление:</label>
                     <input type="text" name="extra_email" :value="this.USER.cinema.requisite.contact_email"/>

                       <!-- <label>Комментарий</label>
                        <textarea name="text" > </textarea> -->
                        <input type="hidden" name="access_token" value="kl8kn8uf6qh83cz72otms3qs" />
                          <!-- kl8kn8uf6qh83cz72otms3qs   nevafilm
                          afj1j9rk0f5pnurxmi9witt1   - pindik2012

                          return urls can be fully qualified -OR-
                             start with / for root relative -OR-
                             start with . for url relative -->
                        <input type="hidden" name="success_url" value="https://cinemaemotion.pro/static/anons/sent.html" />
                        <input type="hidden" name="error_url" value="https://cinemaemotion.pro/static/anons/error.html" />


                        <!-- set the reply-to address -->
                        <!-- <input type="text" name="reply_to"
                                    placeholder="Your Email" /> -->

                        <!-- to append extra fields, use the extra_ prefix.
                            Entries will be appended to your message body. -->
                        <!-- <input type="text" name="extra_phone_number"
                                    placeholder="Phone Number" /> -->

                        <!-- to split your message into 160 chars
                             for an sms gateway -->
                        <!-- <input type="hidden"
                                    name="sms_format" value="true" /> -->
                        <br><br>
                        <input id="submit_form"  class="btn colored" type="submit" value="Отправить" />
                        <!-- not required, but we'd appreciate it if you'd link to us somewhere on your site -->

                </form>


                    <!--<button type="submit" :class="['btn colored offset-top-20']" @click.prevent="sendRequest" v-if="checkAnons">Отправить запрос на предзаказ</button>-->
                </div>
            </div>

        </div>
        <template v-if="show.contract_error">
            <div class="modal-bg" style="display: block;"></div>
            <div class="modal small open" style="top: 25%">
                <div class="modal-header">
                    <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="show.contract_error = false"></button>
                    <p class="h3">Не хватает данных</p>
                    <ul>
                        <li v-if="checkRequisite === false" class="pointer"><a @click.prevent="$set(temp, 'create_requisite', true)">Реквизиты кинотеатра</a></li>
                        <li v-if="checkRequestDates === false">Срок показа</li>
                    </ul>
                </div>
            </div>
        </template>
        <CreateContact v-if="temp.create_contact" @close="$set(temp, 'create_contact', false)"></CreateContact>
        <CreateRequisite v-if="temp.create_requisite" @close="closeCreateRequisite"></CreateRequisite>
    </div>
</template>

<script>
    import axios from "axios";
    import router from "@/Routes";
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"
    // import Dataset from "@/assets/js/Dataset"
    import einput from "@/assets/js/inputs/einput";
    //import etextarea from "@/assets/js/inputs/etextarea";
    import CreateContact from "@/components/account/profile/contacts/CreateContact";
    import CreateRequisite from "@/components/account/profile/requisites/CreateRequisite";

    export default {
        name: 'Request',
        components: { einput, CreateContact, CreateRequisite },
        data() {
            return {
                router: router,
                request: {},
                file: null,
                show: {
                    description: false,
                    info: false,
                    tech: false,
                    requisite: false,
                    contact: false,
                    request: false,
                    contract: false,
                },
                ContractIs:false,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
                'REQUESTS_BY_ID',
            ]),
            checkContractFileSize() {
                if (this.file !== null && this.file.size > 5242880) {
                    console.log("chek Размер1")
                    console.log(this.file.size)
                    return true
                }
                else return false
            },
            checkContract() {
                console.log("chek Контракт")
                console.log(this.USER.cinema.contract)
                console.log("Размер")
                console.log("Контракт существует?")
                console.log(this.ContractIs)


                //console.log(this.file.size)

                if (this.USER.cinema.contract !== null ) return true

                else return false
            },
            checkContractFileType() {
                console.log("chek file type")
                console.log(this.file)
                console.log(this.file.type)
                if (this.file !== null && this.file.type !== 'application/pdf' && this.file.type !== 'image/jpeg') {
                    return true
                }
                else return false
            },
            getMinDate() {
                let min_date = new Date();
                min_date.setDate(new Date().getDate() + 1);
                return min_date.toISOString().slice(0, 10);
            },
            getMaxDate() {
                if (!this.temp.date_start) {
                    let max_date = new Date(this.getMinDate);
                    max_date.setDate(max_date.getDate() + 30);
                    return max_date.toISOString().slice(0, 10);
                }
                else {
                    let max_date = new Date(this.temp.date_start);
                    max_date.setDate(max_date.getDate() + 30);
                    return max_date.toISOString().slice(0, 10);
                }
            },
            checkStatus() {
                console.log("chekStatus")
                console.log(this.request.status)
                console.log("Контракт существует?")
                console.log(this.ContractIs)
                console.log("Anons?")
                console.log(this.request.film.title)
                console.log(this.request.film.anons)


                return this.request.status === 'Отправлена'

            },
            checkAnons() {

                console.log("Chek anons?")
                console.log(this.request.film.anons)
                console.log(this.USER.cinema.requisite.contact_email)

                return this.request.film.anons===1

            },

             ThemeAnons() {
                return ("Предзаказ на фильм "+ this.request.film.title )

            },


            checkContacts() {
                return this.USER.cinema.contacts && this.USER.cinema.contacts.length > 0
            },
            checkRequisite() {
                if (this.USER.cinema.requisite !== null && typeof(this.USER.cinema.requisite) === 'object' && Object.keys(this.USER.cinema.requisite).length > 0) {
                    for (let prop in this.USER.cinema.requisite) {
                        if (!this.USER.cinema.requisite[prop]) return false;
                    }
                    return true
                }
                else return false
            },
            checkCreateContract() {
                return this.checkRequisite > 0 && this.checkRequestDates;
            },
            checkRequestDates() {
                return this.temp.date_start !== null && this.temp.date_start !== ''
                    && this.temp.date_end !== null && this.temp.date_end !== ''
            },
            checkSendRequest() {
                return  this.checkRequisite === false || this.checkRequestDates === false ;
            },
        },
        mounted() {
            if (this.USER.requests.length > 0) {
                if (this.REQUESTS_BY_ID[this.$route.params.id]) {
                    document.title = `CinemaEmotion — заявка на фильм "${this.REQUESTS_BY_ID[this.$route.params.id].film.title}"`;
                    this.request = this.REQUESTS_BY_ID[this.$route.params.id]
                }
                this.temp = this.deepCopy(this.request);
                if (!this.request.requisite) this.$set(this.request, 'requisite', {});
                if (this.request.contact && typeof(this.request.contact) === 'object') {
                    this.$set(this.temp, 'contact_id', this.request.contact.id);
                    this.$set(this.temp, 'letter_id', this.request.contact.id);
                }
                else this.$set(this.temp, 'contact_id', '');
                this.temp.token = document.getElementsByName('csrfmiddlewaretoken')[0].value;

            }
            else {
                axios
                    .get('/api/v1/application/' + this.$route.params.id,{
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        document.title = `CinemaEmotion — заявка на фильм "${response.data.film.title}"`;
                        this.request = response.data;
                        this.temp = this.deepCopy(response.data);
                        if (!response.data.requisite) this.$set(this.request, 'requisite', {})
                        if (response.data.contact && typeof(response.data.contact)) {
                            this.$set(this.temp, 'contact_id', response.data.contact.id);
                            this.$set(this.temp, 'letter_contact', response.data.contact.id);

                        }
                        else this.$set(this.temp, 'contact_id', '');
                        this.temp.token = document.getElementsByName('csrfmiddlewaretoken')[0].value;

                    })
                    .catch(error => {
                        if( error.response.status === 406 && error.response.statusText === 'Not Acceptable') router.push('/')
                        else console.log(error.response.data.__main__)
                    })
            }
            if (this.request.contract !== null ) this.show.request = true;
            else this.show.contract = true;
            if (this.USER.cinema.requisite !== null) this.$set(this.temp, 'requisite', this.deepCopy(this.USER.cinema.requisite));
            else this.$set(this.temp, 'requisite', {});
        },
        methods: {
            closeCreateRequisite() {
                this.$set(this.temp, 'create_requisite', false);
                this.$set(this.show, 'contract_error', false);
            },
            setFile(file) {
                this.$set(this, 'file', file);
            },
            setContact(event) {
                this.$set(this.temp, 'contact_id', event ? event.id : null);
                this.$set(this.temp, 'letter_contact', event ? event.id : null);
                this.$set(this.temp, 'contact', event ? event.id : null);
                this.cleanError('contact');
            },
            createContract() {
                if (this.checkCreateContract) {
                console.log("checkCreateContract")
                console.log(this.checkCreateContract)
                     this.ContractIs=true;
                    this.$refs.contract.submit();
                }
                else {
                    this.$set(this.show, 'contract_error', true);
                }
            },
            createContact() {
                let data = new FormData;
                data.append('contact', this.temp.contact);
                axios
                    .post('/create/contract', data, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true,
                        responseType: 'arraybuffer',
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }})
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'contract.pdf');
                        document.body.appendChild(link);
                        link.click();

                    })
                    .catch(error => console.log(error.response));
            },
            submitData(event, action) {
                let data =  new FormData(event.target);
                let method;
                if (Object.keys(this.USER.cinema.requisite).length > 0) {
                    method = 'patch';
                    action += '/0';
                } else {
                    method = 'post';
                }
//                    .patch('/' + action, data, {
//                        headers: })
                axios({method: method, url: action, data: data, headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                    .then(response => {
                        this.$store.commit('UPDATE_REQUISITE', response.data.object);
                        this.temp.create_requisite = false;
                    })
                    .catch(error => this.setError(error.response.data))
            },
            uploadContract(action) {
                let data =  new FormData();
                data.append('contract', this.temp.upload);
                axios
                    .patch('/' + action + '/' + this.$route.params.id, data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => console.log(response.data))
                    .catch(error => console.log(error.response));
            },
            updateRequest(event, action) {
                let data = new FormData(event.target)
                axios
                    .patch('/' + action + '/' + this.$route.params.id, data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        this.$set(this, 'request', response.data.object);
                        this.$store.commit('UPDATE_REQUEST', response.data.object)
                    })
                    .catch(error => console.log(error.response))
            },
            sendRequest() {
                axios
                    .post(`/api/v1/application/${this.$route.params.id}/send`)
                    .then(response => {
                        console.log(response);
                        this.$store.commit('UPDATE_REQUEST', response.data.object)
                        router.push('/account/requests')
                    })
                    .catch(error => console.log(error.response))
            },

            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.$set(this.temp, 'upload',(files[0]));
            },
        },

    }
</script>

<style>
    .pointer {
        cursor: pointer;
    }
    .fs-inherit {
        font-size: inherit!important;
    }
    .fade-enter-active, .fade-leave-active {
         transition: opacity .5s;
     }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }
        .create-enter-active {
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .create-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .create-enter-to, .create-leave {
        max-height: 400px;
        overflow: hidden;
    }

    .create-enter, .create-leave-to {
        overflow: hidden;
        max-height: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .error-enter-active {
        -moz-transition-duration: 0.5s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .error-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
    }

    .error-enter-to, .error-leave {
        max-height: 400px;
        overflow: hidden;
    }

    .error-enter, .error-leave-to {
        overflow: hidden;
        max-height: 0;
        /*padding-top: 5px;*/
        /*padding-bottom: 5px;*/
    }
</style>
