import Catalogue from "@/components/Catalogue"
import Registration from "@/components/Registration"
import Film from "@/components/Film"
import Profile from "@/components/account/Profile"
// import Contracts from "@/components/account/Contracts"
// import Contract from "@/components/account/contracts/Contract"
import Requests from "@/components/account/Requests"
import Request from "@/components/account/requests/Request"
import Account from "@/components/Account"
import Info from "@/components/account/profile/Info"
//import Contacts from "@/components/account/profile/Contacts"
import Requisites from "@/components/account/profile/Requisites"
//import CreateContact from "@/components/account/profile/contacts/CreateContact"
import KDM from "@/components/account/profile/KDM"
import NotFound from "@/components/service/NotFound"
import Confirmation from "@/components/service/Confirmation"
import ConfirmationSuccess from "@/components/service/confirmation/ConfirmationSuccess"
import ConfirmationFailure from "@/components/service/confirmation/ConfirmationFailure"
import ConfirmationMail from "@/components/service/confirmation/ConfirmationMail"
import ResetPassword from "@/components/service/ResetPassword"
import Vue from "vue";
import VueRouter from "vue-router";
// import navbar from "@/modules/routes/navbar.js"
Vue.use(VueRouter);
const router = new VueRouter({
    routes: [
        {
            path: '/', component: Catalogue, props: true, meta: {title: 'CinemaEmotion'},
            children: [
                {path: 'registration', component: Registration, props: true},
                {path: 'login', component: Registration, props: true}
            ]
        },
        {
            path: '/catalogue/:id/', component: Film, props: true, meta: {title: 'CinemaEmotion — Информация о фильме' },
            children: [
                {path: 'registration', component: Registration, props: true},
                {path: 'login', component: Registration, props: true}
            ]
        },
        {
            path: '/account/', component: Account, props: true, meta: {title: "CinemaEmotion"},
            children: [
                {
                    path: 'profile',
                    component: Profile,
                    props: true,
                    meta: {title: 'CinemaEmotion — профиль'},
                    children: [
                        {path: 'info', component: Info, props: true, meta: {title: 'CinemaEmotion — Информация о кинотеатре'},},
                        // {path: 'contacts', component: Contacts, props: true, meta: {title: 'CinemaEmotion — Контактные лица кинотеатра'},
                        //     children: [
                        //         {path: 'create', component: CreateContact, props: true},
                        //     ]
                        // },
                        {path: 'requisites', component: Requisites, props: true, meta: {title: 'CinemaEmotion — Реквизиты кинотеатра'}},
                        {path: 'KDM', component: KDM, props: true, meta: {title: 'CinemaEmotion — Адреса для рассылки KDM'}},
                    ]
                },
                {
                    path: 'requests', component: Requests, props: true, meta: {title: 'CinemaEmotion — Список заявок'},
                    children: [
                        {path: ':id', component: Request, props: true, meta: {title: 'CinemaEmotion — Информация о заявке'}},
                    ],
                },
                ]
        },
        {
            path: '/confirmation', component: Confirmation, props: true,
            children: [
                {path: 'mail', component: ConfirmationMail, props: true},
                {path: 'success', component: ConfirmationSuccess, props: true},
                {path: 'failure', component: ConfirmationFailure, props: true},
            ],
        },
        {path: '/password/reset/:hash', component: ResetPassword, props: true, meta: {title: 'CinemaEmotion — Восстановление пароля'}},
        {path: '*', component: NotFound, title: '404', props: true, meta: {title: 'CinemaEmotion — 404'}},
    ],
    mode: 'history'
});
router.afterEach((to) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
});
// const routes = baseRoutes.concat(navbar)
export default router;
