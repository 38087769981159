<template>
    <main>

        <div class="row-wrap center-block">
            <div>
                <div class="error-404">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </div>
                <p class="h1">В данный момент страница недоступна</p>
                <p>И мы не знаем, по какой причине.</p>
                <router-link :to="router.go(-1)">Вернуться на предыдущую страницу</router-link>
            </div>
        </div>

    </main>
</template>

<script>
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"



    export default {
        name: 'NotFound',
        data() {
            return {
                router: router,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
    }
</script>

<style>
</style>
