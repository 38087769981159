<template>
            <div class="xsmall-12 medium-6 section">
                <span class="fas fa-exclamation-circle large-icon offset-bottom-20"></span>
                <h1>Для завершения регистрации необходимо подтвердить вашу почту</h1>
                <p>
                    На почту, которую вы указали при регистрации, отправлено письмо с кодом активации
                </p>
            </div>
</template>

<script>
    // import axios from 'axios'
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"



    export default {
        name: 'ConfirmationMail',
        data() {
            return {
                router: router,
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
    }
</script>

<style>
</style>
