<template>
    <div>
    <div class="modal-bg" style="display: block;" @click.prevent="closeModal(getLocation)"></div>
    <div id="modal-password" class="modal small open" v-if="forgot_password">

            <div class="modal-header">
                <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="forgot_password = false"></button>
                <p class="h3">Восстановление пароля</p>
            </div>
            <template v-if="reset_success === false">
            <form @submit.prevent="submitData($event, '/api/v1/reset')">

                <p>Введите адрес электронной почты,<br>на который мы пришлем информацию для восстановления пароля</p>

                <label>Email</label>
                <einput type="email" v-model="reset" name="email" :class="[{'error' : checkReset}]" :error="errors['email']" @clean_error="cleanError"/>
                <p class="description error" v-if="checkReset">Введите действительный адрес электронной почты</p>

                <div class="modal-footer">
                    <input type="submit" class="btn colored" :class="[{'disabled' : checkReset}]" :disabled="checkReset" value="Восстановить пароль">
                </div>

            </form>
            </template>
            <template v-else>
                <p>На почту {{ reset }} отправлено сообщение с инструкцией по восстановлению пароля</p>
            </template>
        </div>
    <div class="modal small open" v-else>

        <div class="modal-header">
            <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="closeModal(getLocation)"></button>
            <p class="h3">
                <span v-if="router.currentRoute.fullPath.includes('/login')">Вход</span>
                <span v-if="router.currentRoute.fullPath.includes('/registration')">Регистрация кинотеатра</span>
            </p>

        </div>

        <form v-if="router.currentRoute.fullPath.includes('/login')" @submit.prevent="submitData($event, '/api/v1/login')">

            <label>Email</label>
            <einput type="email" v-model="login" name="username" :error="errors['username']" @clean_error="cleanError" :iclass="[{'error' : checkLogin}]" />
<!--            <input type="email" name="email" :class="[{'error' : checkLogin}]" v-model="login">-->
<!--            <p class="description error" v-if="checkEmail">Введите действительный адрес электронной почты</p>-->


            <label>Пароль</label>
            <einput type="password" v-model="password.password" name="password" :error="errors['password']" @clean_error="cleanError"/>
<!--            <input type="password" name="password" :class="[{'error' : checkPassword}]" v-model="password.password">-->
            <p class="description error" v-if="errors['__main__']">{{ errors['__main__'] }}</p>
            <div class="modal-footer">
                <input type="submit" class="btn colored" value="Войти" :class="[{'disabled' :checkSubmit}]" :disabled="checkSubmit">
                <a href="#modal-password" class="offset-left-10 js-modal" @click.prevent="forgot_password = true">Забыли пароль?</a>
                <div class="offset-top-10">
                    <a href="#modal-register" class="underline description js-modal" @click.prevent="router.push($route.fullPath.replace('login','') + 'registration')">
                        Еще нет аккаунта? Зарегистрируйся.
                    </a>
                </div>
            </div>

        </form>
        <form v-if="router.currentRoute.fullPath.includes('/registration')" @submit.prevent="submitData($event, 'api/v1/register/cinema')">
            <label>Название</label>
            <einput type="text" v-model="title" name="title" :error="errors['title']" @clean_error="cleanError"></einput>
<!--            <input type="text" autofocus="" name="title" v-model="title">-->
            <p class="description error" v-if="checkTitle">Данное поле является обязательным для заполнения</p>

            <label>Город</label>
            <ewrapper :error="errors['city']">
                <Dataset @search="getCities" :options="cities" @input="city = $event ? $event.id : null; cleanError('city')"
                         label="title" :class="[{'error' : checkCity}]" @clean="city = null"></Dataset>
            </ewrapper>
            <p class="description error" v-if="checkCity">Выберите город из списка</p>
            <input type="hidden" name="city" v-model="city">

            <label>Email</label>
            <einput type="email" v-model="email" name="email" :error="errors['email']" @clean_error="cleanError" :iclass="[{'error' : checkEmail}]"></einput>
<!--            <input type="email" name="email" v-model="email" :class="[{'error' : checkEmail}]">-->
            <p class="description error" v-if="checkEmail">Введите действительный адрес электронной почты</p>

            <label>Пароль</label>
            <einput type="password" v-model="password[1]" name="password1" :error="errors['password1']" @clean_error="cleanError" :iclass="[{'error' : checkFirstPassword}]"></einput>
            <p class="description error" v-if="checkFirstPassword">Пароль должен содержать как минимум одну заглавную букву, одну цифру и один специальный символ из следующего набора: @$!%*?&</p>
<!--            <input type="password" name="password1" v-model="password[1]">-->

            <label>Повторите пароль</label>
            <einput type="password" v-model="password[2]" name="password2" :error="errors['password2']" @clean_error="cleanError" :iclass="[{'error' : checkPasswords}]"></einput>
<!--            <input type="password" name="password2" v-model="password[2]" :class="[{'error' : checkPasswords}]">-->
            <p class="description error" v-if="checkPasswords">Введенные пароли не совпадают</p>

            <div class="modal-footer">
                <p class="description">
                    Нажимая кнопку «Зарегистрироваться», я даю <a href="/static/agreement_pro.pdf" target="_blank">согласие на
                    обработку персональных данных</a> и принимаю условия <a href="/static/privacy_pro.pdf" target="_blank">Политики конфиденциальности</a>.
                </p>
                <input type="submit" class="btn colored" value="Зарегистрироваться" :class="[{'disabled' : checkSubmit}]" :disabled="checkSubmit">
            </div>

        </form>
    </div>
    </div>
</template>

<script>
    import axios from "axios";
    import router from "@/Routes";
    import Dataset from "@/assets/js/Dataset";
    import einput from "@/assets/js/inputs/einput";
    import ewrapper from "../assets/js/inputs/ewrapper";

    export default {
        name: 'Registration',
        components: {
            ewrapper,
            einput,
            Dataset,
        },
        data() {
            return {
                router: router,
                cities: [],
                forgot_password: false,
                reset_success: false,
                filterPattern: undefined,
                title: null,
                city: null,
                email: null,
                login: null,
                password: {
                    1: null,
                    2: null,
                    password: null,
                },
                reset: null,
            }
        },
        computed: {
            getLocation() {
                if (this.router.currentRoute.fullPath.includes('/login')) return '/login';
                else return '/registration';
            },
            filteredCities() {
                return this.cities.filter(city => city['title'].startsWith(this.filterPattern))
            },
            checkReset() {
                const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (this.reset !== null && this.reset !== '') {
                    if (email.test(this.reset)) {
                        return false
                    } else return true
                }
                else return true
            },
            checkPasswords() {
                if (this.password[2] !== null) {
                    return this.password[1] !== this.password[2]
                 }
                else return false
            },
            checkPassword() {
                const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                if (this.password.password !== null ) {
                    if (password.test(this.password.password)) {
                        return false
                    }
                    else return true
                }
                else return false;
            },
            checkFirstPassword() {
                const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                if (this.password[1] !== null) {
                    if (password.test(this.password[1])) {
                        return false
                    }
                    else return true
                }
                else return false;
            },
            checkLogin() {
                const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (this.login !== null && this.login !== '') {
                    if (email.test(this.login)) {
                        return false
                    } else return true
                }
                else return false
            },
            checkEmail() {
                const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (this.email !== null && this.email !== '') {
                    if (email.test(this.email)) {
                        return false
                    } else return true
                }
                else return false
            },
            checkTitle() {
                if (this.title !== null) return this.title.length < 1;
                else return false
            },
            checkCity() {
                if (this.city !== null) return this.city.length < 1;
                else return  false
            },
            checkSubmit() {
                if (this.router.currentRoute.fullPath.includes('/registration')) return this.checkTitle || this.checkCity || this.checkEmail || this.checkFirstPassword || this.checkPasswords;
                else if (this.router.currentRoute.fullPath.includes('/login')) return this.checkLogin;
                else return true;
            },
        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {

        },
        methods: {
            closeModal(str) {
                let location = this.router.currentRoute.fullPath;
                this.router.push(location.replace(str, '/'));
            },
            getCities(pattern) {
                this.$set(this, 'filterPattern', pattern)
                if (pattern.length === 3) {
                    axios.get(`/api/v1/city/${pattern}`)
                        .then(response => {
                            this.$set(this, 'cities', response.data)
                        })
                }
            },
            submitData(event, action) {
                let data =  new FormData(event.target);
                axios
                    .post(action, data, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }})
                    .then(response => {
                        if (response.data.user) {
                            this.closeModal(this.getLocation);
                            this.$store.commit('SET_USER', response.data.user);
                        }
                        if (response.data.csrf) {
                            document.getElementsByName('csrfmiddlewaretoken')[0].value = response.data.csrf
                        }
                        if (action.includes('reset') === false && response.data.__main__) router.push('/confirmation/mail')
                        if (action.includes('reset')) this.reset_success = true;

                    })
                    .catch(error => this.setError(error.response.data))
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
