<template>
            <div class="xsmall-12 medium-6 section">
<!--                <span class="fas fa-exclamation-circle large-icon offset-bottom-20"></span>-->
                <h1>FAILURE</h1>
            </div>
</template>

<script>
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"



    export default {
        name: 'ConfirmationFailure',
        data() {
            return {
                router: router,
            }
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
        },
    }
</script>

<style>
</style>
