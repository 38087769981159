<template>
    <div>
        <template v-if="comp_error">
            <template v-for="(err, index) in error">
                <span class="error" v-html="err" :key="'err-' + index"></span>
            </template>
        </template>
        <textarea :class="iclass" :disabled="disabled" :value="value" v-on:input="input" v-on:change="change" :name="name"></textarea>
    </div>
</template>

<script>
    export default {
        name: "etextarea",
        props: {
            error: {
                type: [Object, Array],
                required: false,
            },
            name: {
                type: String,
                required: true,
            },
            value: {
                required: false,
            },
            disabled: {
                default: false,
                type: Boolean,
            },
            iclass: {
                type: [String, Array, Object],
                required: false,
            },
        },
        computed: {
            comp_error: function() {
                return this.error ? this.error : undefined
            },
        },
        methods: {
            input: function($event) {
                this.$emit('input', $event.target.value)
                if (this.comp_error) {
                    this.$emit('clean_error', this.name)
                }
            },
            change: function() {
                if (this.comp_error) {
                    this.$emit('clean_error', this.name)
                }
            }
        }
    }
</script>

<style scoped>

</style>
