import Vue from 'vue'
import Vuex from 'vuex';
import 'es6-promise/auto';
import moduleUser from "@/modules/store/user.js"
import moduleCatalogue from "@/modules/store/catalogue.js"
import moduleGenres from "@/modules/store/genres.js"
// import axios from 'axios';
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: moduleUser,
        catalogue: moduleCatalogue,
        genres: moduleGenres,
    },
    state: {
        service: {},
    },
    mutations: {
        SET_SERVICE(state, service) {
            state['service'][service.field] = service.data
        },
    },
    actions: {
        
    },
    getters: {
        SERVICE(state) {
            return state.service;
        },
    },
});

export default store