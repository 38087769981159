import axios from 'axios';
import Vue from 'vue';

const moduleUser = {
    state: {
        user: {},
    },
    mutations: {
        SET_USER(state, user) {
            Vue.set(state, 'user', user)
            Vue.set(state.user, 'requests', [])
        },
        SET_REQUESTS(state, requests) {
            Vue.set(state.user, 'requests', requests)
        },
        SET_REQUEST(state, request) {
            state.user.requests.push(request);
        },
        UPDATE_REQUEST(state, request) {
            let request_index = state.user.requests.findIndex(item => item.id === request.id)
            Vue.set(state.user.requests, request_index, request)
        },
        CREATE_CONTACT(state, contact) {
            state.user.cinema.contacts.push(contact);
        },

        UPDATE_CONTACT(state, contact) {
            let contact_index = state.user.cinema.contacts.findIndex(item => item.id === contact.id)
            Vue.set(state.user.cinema.contacts, contact_index, contact)
        },
        DELETE_CONTACT(state, contact) {
            console.log(contact, typeof (contact))
            let contact_index = state.user.cinema.contacts.findIndex(item => {
                console.log(item.id)
                return item.id === contact
            })
            console.log(contact_index);
            if (contact_index !== -1) {
                Vue.delete(state.user.cinema.contacts, contact_index)
            }
        },
        UPDATE_CINEMA(state, cinema) {
            Vue.set(state.user, 'cinema', cinema);
        },
        UPDATE_REQUISITE(state, requisite) {
            Vue.set(state.user.cinema, 'requisite', requisite);
        },
        UPDATE_KDM(state, kdm_emails) {
            Vue.set(state.user.cinema, 'kdm_emails', kdm_emails);
        },
        UPDATE_BONUS(state, bonus) {
            Vue.set(state.user.cinema, 'bonus', bonus);
        },
        UPDATE_TRUSTED_USER(state, trusted_user) {
            Vue.set(state.user.cinema, 'bonus', trusted_user);
        },

    },
    getters: {
        USER(state) {
            return state.user;
        },
        REQUESTS_BY_ID(state) {
            return state.user.requests.reduce((acc, curr) => {
                acc[parseInt(curr.id, 10)] = curr
                return acc
            }, {})
        },
        SORTED_REQUESTS(state) {
            return state.user.requests
                .sort((a, b) => {
                if (a.film.title.toLowerCase() > b.film.title.toLowerCase()) {
                    return 1
                }
                if (a.film.title.toLowerCase() < b.film.title.toLowerCase()) {
                    return -1
                }
                return 0
                })
                .sort((a, b) => {
                if (new Date(a.date_added) > new Date(b.date_added)) {
                    return -1
                }
                if (new Date(a.date_added) < new Date(b.date_added)) {
                    return 1
                }
                return 0
            });
        },
    },
        actions: {
            GET_USER_API({commit}) {
                axios
                    .get('/api/v1/user', {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            withCredentials: true
                        }
                    })
                    .then(response => commit('SET_USER', response.data.user))
                    .catch(error => console.log(error))
            },
        },
};

export default moduleUser;
