import axios from 'axios';
import Vue from 'vue';

const moduleGenres = {
    state: {
        genres: [],
    },
    mutations: {
        SET_GENRES(state, genres) {
            Vue.set(state, 'genres', genres)
        },
    },
    getters: {
        GENRES(state) {
            return state.genres;
        },
        SORTED_GENRES(state) {
            return state.genres.sort((a, b) => {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1
                }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1
                }
                return 0
            });
        },
    },
    actions: {
        GET_GENRES_API({commit}) {
            axios
                .get('/api/v1/genres', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                .then(response => {
                    commit('SET_GENRES', response.data.objects)
                })
                .catch(error => console.log(error.response))
        },
    }
};

export default moduleGenres;