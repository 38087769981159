<template>
    <div>
        <div class="modal-bg" style="display: block;" @click.prevent="$emit('close')"></div>
        <div id="modal-contact" class="modal small open">

            <div class="modal-header">
                <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="$emit('close')"></button>
                <p class="h3">Реквизиты кинотеатра</p>
            </div>

            <form @submit.prevent="submitData($event, '/api/v1/cinema/requisite')">
                <label>Юридическое лицо</label>
                <einput type="text" name="title" :iclass="[{'error' : checkTitle}]" :error="errors['title']" @clean_error="cleanError" v-model="temp.title"></einput>
                <p class="description error" v-if="checkTitle">Данное поле обязательно для заполнения</p>

                <fieldset>
                    <legend>Представитель кинотеатра для подписи в договоре</legend>
                    <label>ФИО</label>
                    <einput type="text" class="full" name="agent_fullname" v-model="temp.agent_fullname" :error="errors['agent_fullname']" @clean_error="cleanError"></einput>

                    <label>Должность</label>
                    <einput type="text" class="full" name="agent_position" v-model="temp.agent_position" :error="errors['agent_position']" @clean_error="cleanError"></einput>

                    <label>ФИО в родительном падеже</label>
                    <einput type="text" class="full" name="agent_fullname_gen" v-model="temp.agent_fullname_gen" :error="errors['agent_fullname_gen']" @clean_error="cleanError"></einput>

                    <label>Должность в родительном падеже</label>
                    <einput type="text" class="full" name="agent_position_gen" v-model="temp.agent_position_gen" :error="errors['agent_position_gen']" @clean_error="cleanError"></einput>

                    <label>Представитель действует на основании (устава, доверенности...)</label>
                    <einput type="text" class="full" name="agent_basis" v-model="temp.agent_basis" :error="errors['agent_basis']" @clean_error="cleanError"></einput>

                </fieldset>
                <fieldset>
                    <legend>Контактное лицо</legend>
                    <label>ФИО</label>
                    <einput type="text" class="full" name="contact_fullname" v-model="temp.contact_fullname" :errors="errors['contact_fullname']" @clean_error="cleanError"></einput>
                    <label>Должность</label>
                    <einput type="text" class="full" name="contact_position" v-model="temp.contact_position" :errors="errors['contact_position']" @clean_error="cleanError"></einput>
                    <label>Телефон</label>
                    <einput type="text" class="full" name="contact_phone" v-model="temp.contact_phone" :errors="errors['contact_phone']" @clean_error="cleanError"></einput>
                    <label>Email</label>
                    <einput type="text" class="full" name="contact_email" v-model="temp.contact_email" :errors="errors['contact_email']" @clean_error="cleanError"></einput>
                </fieldset>
                <label>ИНН</label>
                <einput type="number" name="INN" v-model="temp.INN" :error="errors['INN']" @clean_error="cleanError"></einput>
                <label>КПП</label>
                <einput type="text" name="COR" v-model="temp.COR" :error="errors['COR']" @clean_error="cleanError"></einput>
                <label>Расчетный счет</label>
                <einput type="number" name="bill" v-model="temp.bill" :error="errors['bill']" @clean_error="cleanError"></einput>

                <label>Наименование банка</label>
                <etextarea class="small" name="bank" v-model="temp.bank" :error="errors['bank']" @clean_error="cleanError"></etextarea>

                <label>Корреспондентский счет</label>
                <einput type="text" name="kor_bill" v-model="temp.kor_bill" :error="errors['kor_bill']" @clean_error="cleanError"></einput>

                <label>БИК</label>
                <input type="number" name="bik" v-model="temp.bik" :error="errors['bik']" @clean_error="cleanError">

                <div class="btns offset-top-20">
                    <button type="submit" :class="['btn colored', {'disabled' : checkSubmit}]" :disabled="checkSubmit">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from 'vuex'
    // import Dataset from "@/assets/js/Dataset";con
    import einput from "@/assets/js/inputs/einput";
    import etextarea from "@/assets/js/inputs/etextarea";
    // import ewrapper from "@/assets/js/inputs/ewrapper";


    export default {
        name: 'CreateRequisite',
        components: {einput,etextarea},
        data() {
            return {
                router: router,
            }
        },
        mounted() {
            if (!this.USER.cinema.requisite) this.$set(this, 'temp', {})
            else this.$set(this, 'temp', this.deepCopy(this.USER.cinema.requisite))
        },
        computed:{
            ...mapGetters([
                'USER',
            ]),
            checkTitle() {
                if (this.temp.title !== undefined) return this.temp.title.length < 1
                else return true
            },
            checkSubmit() {
                return this.checkTitle
            },
            requisite: function() {
                if (!this.USER.cinema.requisite) {
                    this.$set(this.USER.cinema, 'requisite', {})
                }
                return this.USER.cinema.requisite
            }
        },
        methods: {
            submitData(event, action) {
                let data =  new FormData(event.target);
                let method;
                if (this.USER.cinema.requisite && Object.keys(this.USER.cinema.requisite).length > 0) {
                    method = 'patch';
                    action += '/0';
                } else {
                    method = 'post';
                }
                axios({method: method, url: action, data: data, headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                    .then(response => {
                        this.$store.commit('UPDATE_REQUISITE', response.data.object);
                        this.$emit('close')
                    })
                    .catch(error => this.setError(error.response.data))
            },
        },
    }
</script>

<style>
</style>
