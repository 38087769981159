<template>
    <div>
        <div class="modal-bg" style="display: block;" @click.prevent="$emit('close')"></div>
        <div id="modal-contact" class="modal small open">

            <div class="modal-header">
                <button class="fas fa-times btn colored icon js-modal-close" @click.prevent="$emit('close')"></button>
                <p class="h3">Добавление контактного лица</p>
            </div>

            <form @submit.prevent="submitData($event, '/api/v1/cinema/contacts')">

                <label>ФИО представителя</label>
                <einput type="text" name="full_name" :iclass="[{'error' : checkFullName}]" v-model="temp.full_name" :error="errors['full_name']" @clean_error="cleanError"></einput>

                <label>Должность</label>
                <einput type="text" name="position" :iclass="[{'error' : checkPosition}]" v-model="temp.position" :error="errors['position']" @clean_error="cleanError"></einput>

                <label>Телефон</label>
                <einput type="text" name="phone" :error="errors['phone']" @clean_error="cleanError"></einput>

                <label>Email</label>
                <einput type="text" name="email" :error="errors['email']" @clean_error="cleanError"></einput>

                <div class="modal-footer">
                    <button type="submit" :class="['btn colored', {'error' : checkSubmit}]" :disabled="checkSubmit">Добавить</button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
    // import Vue from 'vue';
    // import Vuex from 'vuex';
    import axios from "axios";
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from 'vuex'
    // import Dataset from "@/assets/js/Dataset";
    import einput from "@/assets/js/inputs/einput";
    // import ewrapper from "@/assets/js/inputs/ewrapper";


    export default {
        name: 'CreateContact',
        components: {einput},
        data() {
            return {
                router: router,
            }
        },
        mounted() {
            this.$set(this.temp, 'full_name', '');
            this.$set(this.temp, 'position', '');
        },
        computed:{
            ...mapGetters([
                'USER',
            ]),
            checkFullName() {
                return this.temp.full_name === undefined || this.temp.full_name.length < 1
            },
            checkPosition() {
                return this.temp.position === undefined || this.temp.position.length < 1
            },
            checkSubmit() {
                return this.checkFullName || this.checkPosition
            },
        },
        methods: {
            submitData(event, action) {
                let data =  new FormData(event.target);
                axios
                    .post(action, data, {
                       headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                    .then(response => {
                        this.$store.commit('CREATE_CONTACT', response.data.object);
                        this.$emit('close', response.data.object);
                    })
                    .catch(error => console.log(error.response.data))
            },
            openForm: function(field, extra_field) {
                this.edit = true;
                if (!extra_field) this.temp = this.deepCopy(this.USER[field]);
                else this.temp = this.deepCopy(this.USER[field][extra_field]);
                if (this.temp.city) this.cities.push(this.temp.city);
            },
        },
    }
</script>

<style>
</style>
