<template>
    <main>

        <div class="row-wrap center-block">
            <div>
                <form @submit.prevent="resetPassword($event)">
                    <p class="description error" v-if="errors['__main__']">{{ errors['__main__'] }}</p>
                    <label>Новый пароль</label>
                    <einput type="password" name="password_new" v-model="temp.password1" :error="errors['password_new']" :iclass="[{'error' : checkFirstPassword}]" @clean_error="cleanError"/>
                    <label>Новый пароль еще раз</label>
                    <einput type="password" name="password_new_repeat" v-model="temp.password2" :error="errors['password_new_repeat']" :iclass="[{'error' : checkSecondPassword}]" @clean_error="cleanError"/>
                    <p class="description error" v-if="checkPasswords">Введенные пароли не совпадают</p>
                    <div class="offset-top-10">
                        <button type="submit" :class="['btn colored', {'disabled' : checkSubmit}]" :disabled="checkSubmit">Сохранить</button>
                    </div>
                </form>
            </div>
        </div>

    </main>
</template>

<script>
    import axios from 'axios'
    import router from "@/Routes"
    import 'es6-promise/auto';
    import {mapGetters} from "vuex"
    import einput from "@/assets/js/inputs/einput"




    export default {
        name: 'ResetPassword',
        data() {
            return {
                router: router,
            }
        },
        components: {einput},
        mounted() {
            this.$set(this.temp, 'password1', '');
            this.$set(this.temp, 'password2', '');
        },
        computed: {
            ...mapGetters([
                'USER',
            ]),
            checkFirstPassword() {
                const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                if (this.temp.password1 !== undefined ) {
                    if (password.test(this.temp.password1)) {
                        return false
                    }
                    else return true
                }
                else return false;
            },
            checkSecondPassword() {
                const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                if (this.temp.password2 !== undefined ) {
                    if (password.test(this.temp.password2)) {
                        return false
                    }
                    else return true
                }
                else return false;
            },
            checkPasswords() {
                if (this.temp.password2 !== '') {
                    return this.temp.password1 !== this.temp.password2
                }
                else return false
            },
            checkSubmit() {
               return this.checkFirstPassword || this.checkSecondPassword || this.checkPasswords
            },
        },
        methods: {
            resetPassword(event) {
                let data = new FormData(event.target);
                axios
                    .post(`/api/v1/reset/${this.$route.params.hash}`, data, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true
                    }})
                    .then(response => {
                        if (response.data.user) {
                            this.closeModal(this.getLocation);
                            this.$store.commit('SET_USER', response.data.user);
                        }
                        if (response.data.__main__) router.push('/login')
                        })
                    .catch(error => {
                        this.setError(error.response)

                    })
            },

        },
    }
</script>

<style>
</style>
