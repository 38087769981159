<template>
    <div>
        <template v-if="comp_error">
            <template v-for="(err, index) in error">
                <span class="error" :key="index" v-html="err"></span>
            </template>
        </template>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "ewrapper",
        data: function() {
            return {
            }
        },
        props: {
            error: {
                type: [Object, Array],
                required: false,
            },
        },
        computed: {
            comp_error: function() {
                return this.error ? this.error : undefined
            },
        },
    }
</script>

<style scoped>

</style>